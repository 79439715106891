/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";
var Parser = require("html-react-parser");

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import {
  apiUrl,
  tagImageUrl,
  appId,
  cateringId,
  deliveryId,
} from "../Helpers/Config";
import { showLoader, hideLoader,stripslashes } from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
} from "../../actions";
import Slider from "react-slick";
import Megaphoneicon from "../../common/images/megaphone.png";
import innerBanner from "../../common/images/inner-banner.jpg";
import CartSideBar from "../Layout/CartSideBar";
import gridimg from "../../common/images/grid.png";
import filterImg from "../../common/images/filter.svg";
import searchimg from "../../common/images/search.svg";
import MenuProductOne from "../../common/images/menu-product.jpg";

const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");
import ReadMoreAndLess from 'react-read-more-less';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      defaultAvilablityId: cookie.load("defaultAvilablityId"),
      cartTriggerFlg: "No",
    };
	
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === cateringId) {
      this.props.history.push("/");
      return;
    }

    var orderOutletId = cookie.load("orderOutletId");
    var skipbrowse = cookie.load("skipbrowse");
    $('.modal-backdrop').remove();
    if (orderOutletId === "" || orderOutletId === undefined && skipbrowse != 'Yes') {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return;
    }

    this.props.getSettings();

    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    this.props.getMenuNavigationList();
		
  }

  sateValChange = (field, value) => {

  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var navigateCommon = this.props.navigateCommon;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
          if(index%2 == 0){
            var class_bg = 'green_back';
          }else{
            var class_bg = 'yellow_back';
          }

          if (loaddata.menu_type == "main") {
            return (
            <div className={"product_sec "+class_bg}>
               <a href={"/products/category/" + loaddata.pro_cate_slug}
                  onClick={(e) => {
                    this.goCategoryProducts(loaddata.pro_cate_slug);
                  }}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                  className="categ category_actives">
                  {loaddata.pro_cate_image != null && loaddata.pro_cate_image != '' ?
                  <img src={navigateCommon.category_image_url+'/'+loaddata.pro_cate_image} alt="" />
                  : <img src={MenuProductOne} alt="" />}
                  <div className="product_container">
                     <div className="product_caption">
                        <h3>{stripslashes(loaddata.menu_custom_title.toLowerCase())}</h3>
                     </div>
                  </div>
               </a>
            </div>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
            <div className={"product_sec "+class_bg}>
               <a href={"/products/subcategory/" + loaddata.pro_subcate_slug}
                  onClick={(e) => {
                    this.goCategoryProducts(loaddata.pro_subcate_slug);
                  }}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                  className="categ category_actives">
                  {loaddata.pro_subcate_image != null && loaddata.pro_subcate_image != '' ?
                  <img src={navigateCommon.subcategory_image_url+'/'+loaddata.pro_subcate_image} alt="" />
                  : <img src={MenuProductOne} alt="" />}
                  <div className="product_container">
                     <div className="product_caption">
                        <h3>{stripslashes(loaddata.menu_custom_title.toLowerCase())}</h3>
                     </div>
                  </div>
               </a>
            </div>
            );
          }
      });

      return mainMenu;
    } else {
      return Parser('<p>No categories found for the date selected</p>');
    }
  }



  render() {

    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
        />
        {/* Header End */}
        
        <div className="common-inner-blckdiv">
        	<div className="common-inner-banner">
        		<img src={innerBanner} />
                      <div className="inner-banner-content">
        		<h2>Menu</h2>
        		<p></p>
                      </div>
        	</div>
        </div>
		
        <section className="product-menu-listing">
	  
		    <div className="container">
             <div className="product-lhs"> 
              <section className="innersection rel">
                 <div className="container">
                    <div className="menu_page">
                       <h2>Discover</h2>
                       <h3>Our Food</h3>
                       <h5>categories</h5>
                       <div className="product_list">
                          {(this.navigateMenuList())}
                          <hr/>
                       </div>
                    </div>
                 </div>
              </section>
            </div>
          </div>
        </section>

        {/* Footer section */}
        <Footer />

        
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }
  
  var promoSource = "";
  if (Object.keys(state.promotionlist).length > 0) {
    promoSource = state.promotionlist[0].common.promo_image_source;
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    zonedetails: zonedetailArr,
	  promotionlist: state.promotionlist,
    promoSource: promoSource,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
	getPromotionList: (customerParam) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Menu);
