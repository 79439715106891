/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { appId, cateringId, apiUrlV2 } from "../../Helpers/Config";
import {
  getReferenceID,
  randomId,
  showLoader,
  hideLoader,
  showAlert,
  showCartItemCount,
  showCustomAlert,
  stripslashes,
} from "../../Helpers/SettingHelper";
import cookie from "react-cookies";
import moment from "moment";
import axios from "axios";
var qs = require("qs");
var Parser = require("html-react-parser");
import ClockImg from "../../../common/images/clock.png";
import CantDlyImg from "../../../common/images/Cant_Deliver.png";

import {
  GET_AVAILABLE_DATE,
  GET_DEFAULTAVL_DATE,
  GET_AVAILABLE_TIME,
} from "../../../actions";

class Datetimelist extends Component {
  constructor(props) {
    super(props);

    const start = moment();
    var monthListHtml = "";
    var selectMonthTxt = "";
    var lastMonthTxt = "";
    for (var mnth = 0; mnth < 6; mnth++) {
      var calcultDate = moment(start).add(mnth, "month");
      var optValue = calcultDate.format("YYYY-MM") + "-01";
      if (mnth === 0) {
        selectMonthTxt = optValue;
      }
      if (mnth === 5) {
        lastMonthTxt = optValue;
      }
      monthListHtml +=
        "<option value='" +
        optValue +
        "' >" +
        calcultDate.format("MMMM YYYY") +
        "</option>";
    }
    monthListHtml =
      monthListHtml !== "" ? Parser(monthListHtml) : monthListHtml;

    this.state = {
      date_flag_key: "START",
      selected_outletid: "",
      available_datelist: [],
      common_datalist: [],
      time_slotlist: [],
      catering_date: "",
      common_timelist: [],
      timeslot_arr: [],
      time_flag_key: "START",
      interval_time_sat: "15",
      catering_time: "",
      date_errorHtml: "",
      time_errorHtml: "",
      break_timeHtml: "",
      breaktime_enable: "No",
      breaktime_count: 1,
      breaktime_list: [],
      catering_date_changflg: "initail",
      action_from_changflg: "",
      monthListHtml: monthListHtml,
      selectedMonth: selectMonthTxt,
      lastMonth: lastMonthTxt,
    };

    this.getAvailabileDate();

    /*var proVenueState = this.props.venueState;
		console.log(proVenueState);
		console.log('safff');
		console.log(proVenueState.catering_outlet_id);*/
  }

  componentDidMount() {}

  /* Change records */
  handleChange(event) {
    console.log(event.target.value);
    /*this.setState({creditLimit: event.target.value});
		  this.loadData(event.target.value,null);*/
  }

  /* get Current Date */
  getCurrentDatetxt() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    return date;
  }

  /* get Current Time */
  getCurrentTimetxt() {
    var today = new Date();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return time;
  }

  componentWillReceiveProps(venueProps) {
    if (
      this.state.selected_outletid !== venueProps.venueState.catering_outlet_id
    ) {
      this.setState(
        {
          selected_outletid: venueProps.venueState.catering_outlet_id,
          date_flag_key: venueProps.venueState.date_flag_key,
          catering_date: venueProps.venueState.catering_date,
        },
        function () {
          this.getAvailabileDate();
        }.bind(this)
      );
    }

    var breaktimeEnable = venueProps.venueState.breaktime_enable;
    var breaktimeCount = venueProps.venueState.breaktime_count;
    var categoryStateArr = venueProps.venueState.breaktime_list;
    this.setState({
      breaktime_enable: breaktimeEnable,
      breaktime_count: breaktimeCount,
      breaktime_list: categoryStateArr,
    });

    if (this.state.available_datelist !== venueProps.availabiledatelist) {
      var dateArrList = venueProps.availabiledatelist;
      this.setState({
        available_datelist: dateArrList,
        common_datalist: venueProps.commondatalist,
        time_slotlist: venueProps.timeslotlist,
        interval_time_sat: venueProps.intervaltimesat,
      });

      hideLoader("datepicker_main_div", "class");

      var catergDate = this.state.catering_date;
      if (catergDate == "" && Object.keys(dateArrList).length > 0) {
        $.each(dateArrList, function (i, e) {
          if (e.isclose == "No") {
            catergDate = e.fulldate;
            return false;
          }
        });
        this.setState({ catering_date: catergDate });
        this.props.onChangeFl("catering_date", catergDate);
      }

      var outletId = venueProps.venueState.catering_outlet_id;
      if (outletId !== "") {
        this.props.onChangeFl("date_changflg", "changed");
      }

      /*var errorHtml = (Object.keys(dateArrList).length > 0) ? "" : "Temasek Club Catering not available for this Outlet, please call 123441313 for more informations";*/

      var errorHtml =
        Object.keys(dateArrList).length > 0
          ? ""
          : "Catering not available for this Outlet";

      this.setState(
        { date_errorHtml: errorHtml, action_from_changflg: "fromDateAction" },
        function () {
          this.getAvailabileTime("fromDateAction");
        }.bind(this)
      );
    }

    if (this.state.timeslot_arr !== venueProps.availabiletimelist) {
      var timeArrList = venueProps.availabiletimelist;
      var comnTmArrLt = venueProps.commontimelist;
      var cateringDate = this.state.catering_date;
      /*var errorHtml = (Object.keys(timeArrList).length > 0) ? '' : "Temasek Club Catering not available for this date ( "+cateringDate+" ), please call 123441313 for more informations";*/
      var errorHtml = "";
      console.log(timeArrList, "timeArrListtimeArrList");
      var cateringTime =
        Object.keys(timeArrList).length > 0 ? timeArrList[0].timetext : "";
      this.setState({
        timeslot_arr: timeArrList,
        common_timelist: comnTmArrLt,
        time_errorHtml: errorHtml,
      });

      if (cookie.load("catering_eventType") === "hall") {
        cateringTime = "11:00";
      }

      var frmTxt = this.state.action_from_changflg;
      if (frmTxt !== "fromTimeAction") {
        this.setState({ catering_time: cateringTime });
        this.props.onChangeFl("catering_time", cateringTime);
      }
    }
  }

  getAvailabileDate() {
    var outletId = this.props.venueState.catering_outlet_id;
    var dateFlagkey = this.state.date_flag_key;
    var selectMonthTxt = this.state.selectedMonth;
    var nextDate = "";
    var prevDate = "";
    var commonDataList = this.state.common_datalist;
    if (Object.keys(commonDataList).length > 0) {
      nextDate = commonDataList.next_date;
      prevDate = commonDataList.prev_date;
    }

    var avlDateFlgArr = Array();
    avlDateFlgArr["strTime"] = "08:00:00";
    avlDateFlgArr["endTime"] = "20:00:00";
    avlDateFlgArr["dateFlagkey"] = dateFlagkey;
    avlDateFlgArr["nextDate"] = nextDate;
    avlDateFlgArr["prevDate"] = prevDate;
    avlDateFlgArr["selectedDate"] = selectMonthTxt;
    avlDateFlgArr["intervalTime"] = 60;
    avlDateFlgArr["minimumDate"] = 2;
    avlDateFlgArr["maximumDate"] = 90;
    avlDateFlgArr["outletId"] = outletId;

    showLoader("datepicker_main_div", "class");

    this.props.getDefaultAvlDateList(avlDateFlgArr);
  }

  getAvailabileDateHtml() {
    var dateList = this.state.available_datelist;
    var cateringDate = this.state.catering_date;

    var commonDataList = this.state.common_datalist;
    var nextDate = commonDataList.next_date;
    var prevDate = commonDataList.prev_date;

    if (Object.keys(dateList).length > 0) {
      const dateListHtml = this.state.available_datelist.map(
        (dateLst, index) => (
          <div key={index}>
            {index == 10 ? (
              <li
                onClick={this.dateAction.bind(this, "PREV")}
                className={
                  prevDate !== "" && prevDate !== null
                    ? "days-pre-close-disable"
                    : "days-pre-close-disable btn-disable-action"
                }
              >
                <dl className="cl-next-days">
                  <div>
                    <span>Go BAck To Previous</span>
                  </div>
                </dl>
              </li>
            ) : (
              ""
            )}
            <li
              key={index}
              className={
                dateLst.isclose === "Yes"
                  ? "catering_date btn-disable-action"
                  : "catering_date"
              }
              onClick={this.selectDateAction.bind(this, dateLst)}
            >
              <dl
                className={
                  cateringDate == dateLst.fulldate
                    ? "date_inner active"
                    : "date_inner"
                }
              >
                <div>
                  <dt>{dateLst.daytext}</dt>
                  <dd>{dateLst.datetext}</dd>
                </div>
                {/*(dateLst.isclose === 'Yes') ? <div className="tag"><span className="not-available">Not Available</span></div> : ''*/}
              </dl>
            </li>
            {index == 12 ? (
              <li
                onClick={this.dateAction.bind(this, "NEXT")}
                className={
                  nextDate !== "" && nextDate !== null
                    ? "day_next"
                    : "day_next btn-disable-action"
                }
              >
                <dl className="cl-next-days">
                  <div>
                    <span>Show Next Days</span>
                  </div>
                </dl>
              </li>
            ) : (
              ""
            )}
          </div>
        )
      );
      return dateListHtml;
    } else {
      var dateListHtml = "";
      return (
        <div className="timelist_norecord"> No datetime slot available. </div>
      );
    }
  }

  dateAction(actiontxt) {
    var commonDataList = this.state.common_datalist;
    var nextDate = commonDataList.next_date;
    var prevDate = commonDataList.prev_date;
    var outletId = commonDataList.outlet_id;
    if (
      outletId !== "" &&
      ((actiontxt == "NEXT" && nextDate !== "") ||
        (actiontxt == "PREV" && prevDate !== ""))
    ) {
      var lastMonth = this.state.lastMonth;
      var selMonthObject =
        actiontxt == "NEXT" ? nextDate.split("-") : prevDate.split("-");
      var selectedMonth = selMonthObject[0] + "-" + selMonthObject[1] + "-01";

      if (moment(lastMonth) >= moment(selectedMonth)) {
        this.setState(
          { date_flag_key: actiontxt, selectedMonth: selectedMonth },
          function () {
            this.getAvailabileDate();
          }.bind(this)
        );
      }
    }
  }

  selectDateAction(seltDateArr) {
    var seltDate = seltDateArr.fulldate;
    var isclosetxt = seltDateArr.isclose;
    var cateringDate = this.state.catering_date;
    if (seltDate !== "" && cateringDate != seltDate && isclosetxt == "No") {
      this.setState(
        {
          catering_date: seltDate,
          time_flag_key: "START",
          action_from_changflg: "fromDateAction",
        },
        function () {
          this.getAvailabileTime("fromDateAction");
        }.bind(this)
      );
      this.props.onChangeFl("catering_date", seltDate);
      this.props.resetErrorFn();
    }
  }

  getAvailabileTime(frmTxt) {
    var timeSlotList = this.state.time_slotlist;
    var cateringDate = this.state.catering_date;
    var cateringDate = this.state.catering_date;
    // cateringDate = "2024-03-22";
    var breakTimeHtml = "";
    this.setState({ break_timeHtml: breakTimeHtml });
    console.log(
      timeSlotList,
      cateringDate,
      this.state.selected_outletid,
      "this.state.selected_outletid"
    );
    if (
      Object.keys(timeSlotList).length > 0 &&
      cateringDate !== "" &&
      this.state.selected_outletid !== ""
    ) {
      var timeSlotArr = this.getdayBasedTimeList();
      if (Object.keys(timeSlotArr).length > 0) {
        timeSlotArr.map(
          (timeLt, index) =>
            (breakTimeHtml +=
              "<option value='" + timeLt + "'>" + timeLt + "</option>")
        );
      }
      var breakTimeDropDown = Parser(breakTimeHtml);
      this.setState({ break_timeHtml: breakTimeDropDown });
      this.props.onChangeFl("break_timeHtml", breakTimeDropDown);

      var time_flagkey = this.state.time_flag_key;
      var intervalTime = this.state.interval_time_sat;

      var nextTime = "";
      var prevTime = "";
      var commonTimeList = this.state.common_timelist;
      if (Object.keys(commonTimeList).length > 0) {
        nextTime = commonTimeList.next_time;
        prevTime = commonTimeList.prev_time;
      }

      var avlTimeFlgArr = Array();
      avlTimeFlgArr["timeSlotArr"] = timeSlotArr;
      avlTimeFlgArr["intervalTime"] = intervalTime;
      avlTimeFlgArr["time_flagkey"] = time_flagkey;
      avlTimeFlgArr["nextTime"] = nextTime;
      avlTimeFlgArr["prevTime"] = prevTime;
      console.log(avlTimeFlgArr, "avlDateFlgArravlDateFlgArravlDateFlgArr");
      this.props.getAvailabileTimeList(avlTimeFlgArr);
    } else {
      /*var errorHtml = "Temasek Club Catering not available for this date ( "+cateringDate+" ), please call 123441313 for more informations";*/
      var errorHtml = "";
      this.setState({ time_errorHtml: errorHtml });
    }
  }

  getAvailabileTimeHtml() {
    var timeSlotArry = this.state.timeslot_arr;
    var cateringDate = this.state.catering_date;
    var cateringTime = this.state.catering_time;
    var outletIdTxt = this.state.selected_outletid;

    var commonTimeList = this.state.common_timelist;
    var nextTime = commonTimeList.next_time;
    var prevTime = commonTimeList.prev_time;
    console.log(
      timeSlotArry,
      cateringDate,
      outletIdTxt,
      "outletIdTxtoutletIdTxt"
    );
    if (
      Object.keys(timeSlotArry).length > 0 &&
      cateringDate !== "" &&
      outletIdTxt !== ""
    ) {
      const timeListHtml = timeSlotArry.map((timeLst, index) => (
        <div key={index}>
          {index == 10 ? (
            <li
              onClick={this.timeAction.bind(this, "PREV")}
              className={
                prevTime !== "" && prevTime !== null
                  ? "time-pre-close-disable"
                  : "time-pre-close-disable btn-disable-action"
              }
            >
              <dl className="cl-next-days">
                <div>
                  <span>Go BAck To Previous</span>
                </div>
              </dl>
            </li>
          ) : (
            ""
          )}
          <li
            key={index}
            className={
              timeLst.isclose === "Yes"
                ? "catering_time btn-disable-action"
                : "catering_time"
            }
            onClick={this.selectTimeAction.bind(this, timeLst)}
          >
            <dl
              className={
                cateringTime == timeLst.timetext
                  ? "time_inner active"
                  : "time_inner"
              }
            >
              <div>
                <dt>Time</dt>
                <dd>{timeLst.timetext}</dd>
              </div>
              {/*(timeLst.isclose === 'Yes') ? <div className="tag"><span className="not-available">Not Available</span></div> : ''*/}
            </dl>
          </li>
          {index == 12 ? (
            <li
              onClick={this.timeAction.bind(this, "NEXT")}
              className={
                nextTime !== "" && nextTime !== null
                  ? "catering_time"
                  : "catering_time btn-disable-action"
              }
            >
              <dl className="cl-next-days">
                <div>
                  <span>Show Next Time</span>
                </div>
              </dl>
            </li>
          ) : (
            ""
          )}
        </div>
      ));
      return timeListHtml;
    } else {
      return (
        <div className="timelist_norecord">
          {" "}
          {outletIdTxt !== ""
            ? "No time slot available."
            : "Please choose the outlet first."}{" "}
        </div>
      );
    }
  }

  timeAction(actiontxt) {
    var commonTimeList = this.state.common_timelist;
    var cateringDate = this.state.catering_date;
    var nextTime = commonTimeList.next_time;
    var prevTime = commonTimeList.prev_time;
    if (
      cateringDate !== "" &&
      ((actiontxt == "NEXT" && nextTime !== "" && nextTime !== null) ||
        (actiontxt == "PREV" && prevTime !== "" && prevTime !== null))
    ) {
      this.setState(
        { time_flag_key: actiontxt, action_from_changflg: "fromTimeAction" },
        function () {
          this.getAvailabileTime("fromTimeAction");
        }.bind(this)
      );
    }
  }

  selectTimeAction(seltTimeArr) {
    var seltTime = seltTimeArr.timetext;
    var isclosetxt = seltTimeArr.isclose;
    var cateringTime = this.state.catering_time;
    if (seltTime !== "" && cateringTime != seltTime && isclosetxt == "No") {
      this.setState({ catering_time: seltTime });
      this.props.onChangeFl("catering_time", seltTime);
      this.props.resetErrorFn();
    }
  }

  selectTimeSlotAction(event) {
    var timsltvl = event.target.value;
    if (timsltvl !== "") {
      this.setState({ catering_time: timsltvl });
      this.props.onChangeFl("catering_time", timsltvl);
      this.props.resetErrorFn();
    }
  }

  getdayBasedTimeList() {
    var timeSltList = this.state.time_slotlist;
    var caterngDate = this.state.catering_date;
    var selectedDate = new Date(caterngDate);
    var dayintt = selectedDate.getDay();

    var currentslotData = [];
    if (dayintt == 0) {
      currentslotData = timeSltList.sun;
    }
    if (dayintt == 1) {
      currentslotData = timeSltList.mon;
    }
    if (dayintt == 2) {
      currentslotData = timeSltList.tue;
    }
    if (dayintt == 3) {
      currentslotData = timeSltList.wed;
    }
    if (dayintt == 4) {
      currentslotData = timeSltList.thu;
    }
    if (dayintt == 5) {
      currentslotData = timeSltList.fri;
    }
    if (dayintt == 6) {
      currentslotData = timeSltList.sat;
    }

    return currentslotData;
  }

  getBreakTimeHtml() {
    var breaktime_count = this.state.breaktime_count;
    var breakTimeList = this.state.breaktime_list;
    var breakCountArr = Array();
    if (breaktime_count > 0) {
      var i;
      for (i = 0; i < breaktime_count; i++) {
        var brkVl = !(i in breakTimeList) ? "" : breakTimeList[i];
        breakCountArr[i] = brkVl;
      }
    }

    if (Object.keys(breakCountArr).length > 0) {
      const breakListHtml = breakCountArr.map((breakm, index) => (
        <li key={index} className="time-cls">
          <label>Select Your Break Time {index + 1}</label>
          <div className="form-group custom-select-bxcls">
            <div className="custom-select-inner">
              <span className="clock_icon">
                <img src={ClockImg} />
              </span>
              <select
                name="breaktime_inp"
                value={breakm}
                className="datetimepicker filled breaktime_listcls"
                onChange={this.handleBrkTimeCreation.bind(this, index)}
                id={"breaktime-" + index}
              >
                {this.state.break_timeHtml}
              </select>
            </div>
          </div>
        </li>
      ));
      return breakListHtml;
    } else {
      var breakListHtml = "";
      return breakListHtml;
    }
  }

  handleBrkTimeCreation(indFlg, event) {
    var totalItems = cookie.load("cartTotalItems");
    if (parseInt(totalItems) > 0) {
      $.magnificPopup.open({
        items: {
          src: "#swtch_popup_id",
        },
        type: "inline",
      });
    } else {
      var brkTmVal = event.target.value;
      var breakTimeList = this.state.breaktime_list;
      breakTimeList[indFlg] = brkTmVal;
      this.setState({ breaktime_list: breakTimeList });
      this.props.resetErrorFn();
    }
  }

  /* Change records */
  handleChangeTest(event) {
    console.log(event.target.value);
    /*this.setState({creditLimit: event.target.value});
		  this.loadData(event.target.value,null);*/
  }
  /* Change records */
  handleChangeBreak(event) {
    var totalItems = cookie.load("cartTotalItems");
    if (parseInt(totalItems) > 0) {
      $.magnificPopup.open({
        items: {
          src: "#swtch_popup_id",
        },
        type: "inline",
      });
    } else {
      var brkCnt = event.target.value;
      brkCnt = brkCnt !== "" ? brkCnt : 0;
      brkCnt = parseInt(brkCnt) < 5 ? brkCnt : 5;
      this.setState({ breaktime_count: brkCnt });
      this.props.onChangeFl("breaktime_count", brkCnt);
      this.props.resetErrorFn();
    }
  }

  handleClickBreak(type) {
    var totalItems = cookie.load("cartTotalItems");
    if (parseInt(totalItems) > 0) {
      $.magnificPopup.open({
        items: {
          src: "#swtch_popup_id",
        },
        type: "inline",
      });
    } else {
      var brkTimeCnt = this.state.breaktime_count;
      if (type === "incs") {
        var brkCnt = document.getElementById("brk_time_count").value;
        brkCnt = brkCnt !== "" ? brkCnt : 0;
        if (parseInt(brkCnt) < 5) {
          brkTimeCnt = parseInt(brkCnt) + 1;
        }
      } else if (type === "decs") {
        var brkCnt = document.getElementById("brk_time_count").value;
        brkCnt = brkCnt !== "" ? brkCnt : 0;
        if (parseInt(brkCnt) > 1) {
          brkTimeCnt = parseInt(brkCnt) - 1;
        }
      }
      this.setState({ breaktime_count: brkTimeCnt });
      this.props.onChangeFl("breaktime_count", brkTimeCnt);
      this.props.resetErrorFn();
    }
  }

  handleClickBtn(type) {
    var totalItems = cookie.load("cartTotalItems");
    var chkType = this.state.breaktime_enable;
    if (parseInt(totalItems) > 0 && chkType !== type) {
      $.magnificPopup.open({
        items: {
          src: "#swtch_popup_id",
        },
        type: "inline",
      });
    } else {
      var NoBtnelement = document.getElementById("nobtn_id");
      var YesBtnelement = document.getElementById("yesbtn_id");
      NoBtnelement.classList.remove("yes");
      NoBtnelement.classList.remove("no");
      YesBtnelement.classList.remove("yes");
      YesBtnelement.classList.remove("no");
      if (type === "No") {
        NoBtnelement.classList.add("yes");
        YesBtnelement.classList.add("no");
        document.getElementById("breaktime_div").style.display = "none";
      } else if (type === "Yes") {
        NoBtnelement.classList.add("no");
        YesBtnelement.classList.add("yes");
        document.getElementById("breaktime_div").style.display = "block";
      }
      this.setState({ breaktime_enable: type });
      this.props.onChangeFl("breaktime_enable", type);
      this.props.resetErrorFn();
    }
  }

  chkBrkTmFun(type, value) {
    var breaktimeEnable = this.state.breaktime_enable;
    var divShowText = "none";
    var NoBtnClass = "button yes";
    var YesBtnClass = "button no";
    if (breaktimeEnable === "Yes") {
      divShowText = "block";
      NoBtnClass = "button no";
      YesBtnClass = "button yes";
    }

    if (type === "text") {
      return divShowText;
    } else if (type === "class" && value === "Yes") {
      return YesBtnClass;
    } else if (type === "class" && value === "No") {
      return NoBtnClass;
    }
  }

  disableTimeSltOption(fromTxt) {
    var eventtype = cookie.load("catering_eventType");
    var divShowText = "";
    if (fromTxt === "timebox") {
      divShowText = eventtype === "hall" ? "none" : "block";
    } else if (fromTxt === "timeslot") {
      divShowText = eventtype === "hall" ? "block" : "none";
    }
    return divShowText;
  }

  yesProccdd() {
    this.cartDestoryNew();
    this.props.setActiveProTab(2);
  }

  cartDestoryNew() {
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          showCustomAlert("success", "Nice! Your cart is empty");
          $.magnificPopup.close();
          var cartDetails = Array();
          showCartItemCount(cartDetails);
          /*browserHistory.push("/#/");*/
        } else {
          $.magnificPopup.close();
          showCustomAlert("error", "Sorry! Products can`t update your cart");
        }
      })
      .catch(function (error) {
        console.log(error);
        showCustomAlert("error", "something went wrong");
        $.magnificPopup.close();
      });
  }

  monthChange(event) {
    var monthtvl = event.target.value;
    this.setState(
      { selectedMonth: monthtvl, date_flag_key: "START", common_datalist: [] },
      function () {
        this.getAvailabileDate();
      }.bind(this)
    );
  }

  /* ViewProducts */

  render() {
    var dateErrorHtml = this.state.date_errorHtml;
    var timeErrorHtml = this.state.time_errorHtml;

    return (
      <div>
        <h3>Choose Date & Time</h3>

        <div className="date_list date_list_maindiv bdr_bf_select">
          <div className="month-select-div">
            <h4>Choose Month</h4>
            <div className="form-group custom_select" id="month_list_div">
              <select
                name="month_list_id"
                onChange={this.monthChange.bind(this)}
                value={this.state.selectedMonth}
                className="form-control"
              >
                {this.state.monthListHtml}
              </select>
            </div>
          </div>

          <div className="choose-date-div">
            <h4>Choose Event Date</h4>
            <div className="resp-datepicker datepicker_main_div">
              <ul className="catering_days_ul">
                {this.getAvailabileDateHtml()}
              </ul>
            </div>
            <p>{dateErrorHtml}</p>
          </div>

          <div
            className="padding-data"
            style={{ display: this.disableTimeSltOption("timebox") }}
          >
            <h4>Choose Event Time</h4>
            <div className="resp-datepicker">
              <ul className="catering_days_ul">
                {this.getAvailabileTimeHtml()}
              </ul>
            </div>
            <p>{timeErrorHtml}</p>
          </div>

          <div
            className="padding-data"
            style={{ display: this.disableTimeSltOption("timeslot") }}
          >
            <h4>Choose Event Time</h4>
            <div className="datepicker-slots">
              <div className="form-group custom_select" id="timeslot_list_div">
                <select
                  name="timeslot_list_id"
                  onChange={this.selectTimeSlotAction.bind(this)}
                  value={this.state.catering_time}
                  className="form-control"
                >
                  <option value="11:00">11am to 3pm</option>
                  <option value="18:00">6pm to 10pm</option>
                </select>
              </div>
            </div>
          </div>

          <div
            className="chosen-alert-loc pkgerror_txt datetimediv_error"
            style={{ display: "none" }}
          >
            <span className="datetime_error_msg">
              Please select Date & Time
            </span>
          </div>
        </div>

        <div
          className="date_list slots_option_div breaktime_main_div bdr_bf_select"
          style={{ display: "none" }}
        >
          <div className="ordering-more">
            <h3>Ordering for more time slots in a day?</h3>
            <span className="order-button more-time-slots">
              <a
                href="javascript:void(0);"
                id="nobtn_id"
                className={this.chkBrkTmFun("class", "No")}
                onClick={this.handleClickBtn.bind(this, "No")}
              >
                No
              </a>
              <a
                href="javascript:void(0);"
                id="yesbtn_id"
                className={this.chkBrkTmFun("class", "Yes")}
                onClick={this.handleClickBtn.bind(this, "Yes")}
              >
                Yes
              </a>
            </span>
          </div>

          <div
            className="order-yes"
            id="breaktime_div"
            style={{ display: this.chkBrkTmFun("text", "") }}
          >
            <div className="order-break">
              <div className="label">Number of Break Time</div>
              <div className="qty_bx catqty_bx">
                <span
                  className="qty_minus checkoutDecQty"
                  id="dec_cnt"
                  onClick={this.handleClickBreak.bind(this, "decs")}
                ></span>
                <input
                  type="text"
                  name="ksafj1"
                  id="brk_time_count"
                  value={this.state.breaktime_count}
                  onChange={this.handleChangeBreak.bind(this)}
                />
                <span
                  className="qty_plus checkoutIncQty"
                  id="inc_cnt"
                  onClick={this.handleClickBreak.bind(this, "incs")}
                ></span>
              </div>
            </div>

            <div className="order-select-brktime">
              <ul>{this.getBreakTimeHtml()}</ul>
            </div>
          </div>

          <div
            className="chosen-alert-loc pkgerror_txt breaktimediv_error"
            style={{ display: "none" }}
          >
            <span className="breaktime_error_msg">
              Please select Break Time
            </span>
          </div>
        </div>

        <div
          className="white-popup mfp-hide popup_sec mdf-popup-customcls mdf_custom_alert"
          id="swtch_popup_id"
          style={{ maxWidth: 500 }}
        >
          <div className="custom_alert1">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header" style={{ textAlign: "center" }}>
                  Wait a second !
                </div>
                <div className="alert_body">
                  <img src={CantDlyImg} />
                  <p>By switching you are about to clear your cart.</p>
                  <p>Do you wish to proceed ?</p>
                  <div className="alt_btns">
                    <a
                      href="javascript:void(0);"
                      className="button popup-modal-dismiss"
                      style={{ background: "#e0dddd" }}
                    >
                      Cancel
                    </a>
                    <a
                      href="javascript:void(0);"
                      className="button btn_yellow"
                      onClick={this.yesProccdd.bind(this)}
                    >
                      Yes Proceed
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var avlDateArr = Array();
  var comnArrLst = Array();
  var tmslotArrLt = Array();
  var intervalTime = 15;
  if (Object.keys(state.availabiledate).length > 0) {
    if (state.availabiledate[0].status === "success") {
      avlDateArr = state.availabiledate[0].result_set;
      comnArrLst = state.availabiledate[0].common_data;
      tmslotArrLt = state.availabiledate[0].timeslot_data;
      intervalTime = state.availabiledate[0].interval_time;
    }
  }

  var avlTimeArr = Array();
  var comTimArrLt = Array();
  if (Object.keys(state.availabiletime).length > 0) {
    if (state.availabiletime[0].status === "success") {
      avlTimeArr = state.availabiletime[0].result_set;
      comTimArrLt = state.availabiletime[0].common_data;
    }
  }
  console.log(
    avlTimeArr,
    state.availabiletime,
    "avlTimeArravlTimeArravlTimeArr"
  );

  return {
    availabiledatelist: avlDateArr,
    commondatalist: comnArrLst,
    timeslotlist: tmslotArrLt,
    intervaltimesat: intervalTime,
    availabiletimelist: avlTimeArr,
    commontimelist: comTimArrLt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDefaultAvlDateList: (avlDateFlg) => {
      dispatch({ type: GET_DEFAULTAVL_DATE, avlDateFlg });
    },
    getAvailabileTimeList: (avlDateFlg) => {
      dispatch({ type: GET_AVAILABLE_TIME, avlDateFlg });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Datetimelist);
