/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  appId,
  apiUrl,
  deliveryId,
  cateringId,
  pickupId,
  apiUrlV2,
  CountryTxt,
  productNoImg,
  baseUrl,
  timThumpUrl,
} from "../../Helpers/Config";
import {
  getReferenceID,
  showLoader,
  hideLoader,
  callImage,
  showAlert,
  timeToConv12,
  dateConvFun,
  showPriceValue,
  showCustomAlert,
  showCartItemCount,
  getGstValue,
  stripslashes,
  jsUcfirstFun,
} from "../../Helpers/SettingHelper";
import cookie from "react-cookies";
import axios from "axios";
var qs = require("qs");
var Parser = require("html-react-parser");

import ClockImg from "../../../common/images/time.png";

class Cartdetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartDetailsMain: [],
      cartItems: [],
      cart_brktime_list: [],
      cart_count: 0,
    };
  }

  componentWillReceiveProps(chekoutProps) {
    if (Object.keys(chekoutProps).length > 0) {
      var chekoutStateArr = chekoutProps.chekoutState;
      for (var key in chekoutStateArr) {
        this.state[key] = chekoutStateArr[key];
      }
    }

    if (
      this.props.chekoutState?.loadCart === true &&
      this.state.active_tabflag === 4
    ) {
      this.loadCartDet();
      this.props.chkoutSateVal("loadCart", false);
    }
  }

  componentDidMount() {
    if (this.state.active_tabflag === 4) {
      /*this.loadCartDet();*/
    }
  }

  loadCartDet() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + cookie.load("referenceId");
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }

    axios
      .get(apiUrlV2 + "cateringcart/contents?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set == "undefined") {
            showAlert("Success", "Sorry!. Your cart is empty.");

            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });

            this.props.prpActiveTab(1);
            var cartDetailsMain = [];
            var cartItems = [];
            this.props.history.push("/catering");
          } else {
            var cartDetailsMain = res.data.result_set.cart_details;
            var cartItems = res.data.result_set.cart_items;
          }
        } else {
          showAlert("Success", "Sorry!. Your cart is empty.");

          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });

          this.props.prpActiveTab(1);
          var cartDetailsMain = [];
          var cartItems = [];
          this.props.history.push("/catering");
        }

        $(".dvLoadingCls").fadeOut(500);
        this.setState(
          { cartDetailsMain: cartDetailsMain, cartItems: cartItems },
          function () {
            this.makeBrkTimeList();
          }.bind(this)
        );
      });
  }

  makeBrkTimeList() {
    var cartDetailsSate = this.state.cartDetailsMain;
    var cartTotalItmCount = 0;
    var cartItems = this.state.cartItems;
    var cartBrkTmList = [];
    if (
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      cartTotalItmCount = cartDetailsSate.cart_total_items;
      var breaktimeCount = cartDetailsSate.cart_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = Array();
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].cart_item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = Array();
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].cart_item_breaktime_indexflag,
              break_time_start: cartItems[key].cart_item_breaktime_started,
              break_time_end: cartItems[key].cart_item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }

    this.setState({ cart_brktime_list: cartBrkTmList });

    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 800);
  }

  getCartDetList() {
    var cartDetailsSate = this.state.cartDetailsMain;
    cookie.save("cartDetail", cartDetailsSate);

    var cartBrktimeLst = this.state.cart_brktime_list;
    if (Object.keys(cartDetailsSate).length > 0) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      if (breaktimeEnable === "Yes" && Object.keys(cartBrktimeLst).length > 0) {
        return this.cartBrkTimeList();
      } else {
        return this.cartListOnly();
      }
    }
  }

  handlerActTab(indx) {
    var ariaAxp = $("#headingTb" + indx)
      .find("a")
      .attr("aria-expanded");
    if (ariaAxp === "true") {
      $("#headingTb" + indx).addClass("act");
    } else {
      $("#headingTb" + indx).removeClass("act");
    }
    $(".panel-heading")
      .not($("#headingTb" + indx))
      .removeClass("act");
    $(".panel-collapse")
      .not($("#collapse" + indx))
      .removeClass("in");
  }

  cartListOnly() {
    var cartItems = this.state.cartItems;
    return (
      <div className="panel panel-default">
        <div
          className="panel-heading act"
          role="tab"
          id="headingTbC1"
          onClick={this.handlerActTab.bind(this, "C1")}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="true"
              href="#collapseC1"
              className=""
            >
              Cart Details
            </a>
          </h4>
        </div>
        <div
          id="collapseC1"
          className="panel-collapse collapse mCustomScrollbar in"
          aria-expanded="true"
          href="#collapseC1"
        >
          <div className="panel-body">{this.cartItemlist(cartItems)}</div>
        </div>
      </div>
    );
  }

  cartHallData() {
    var cartDetailsMn = this.state.cartDetailsMain;
    if (Object.keys(cartDetailsMn).length > 0) {
      if (cartDetailsMn.cart_venue_type === "hall") {
        return (
          <div className="panel panel-default">
            <div
              className="panel-heading"
              role="tab"
              id="headingTbHa1"
              onClick={this.handlerActTab.bind(this, "Ha1")}
            >
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="false"
                  href="#collapseHa1"
                  className="collapsed"
                >
                  Catering Hall Details
                </a>
              </h4>
            </div>
            <div
              id="collapseHa1"
              className="panel-collapse fixed-height-cls collapse mCustomScrollbar"
              aria-expanded="false"
              href="#collapseHa1"
            >
              <div className="panel-body">
                <div className="buffet_setup" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 col-xs-8">
                      <h5>
                        <a href="javascript:void(0)">
                          <span className="title">
                            {cookie.load("cateringHallName")}
                          </span>
                        </a>
                      </h5>
                      <div style={{ paddingLeft: "35px" }}>
                        {cookie.load("cateringHalladdress")}
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-4">
                      <div className="amt">
                        <span>
                          {showPriceValue(cartDetailsMn.cart_hall_charges)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  cartBrkTimeList() {
    var cartBrktimeLst = this.state.cart_brktime_list;

    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => (
      <div className="panel panel-default" key={indx}>
        <div
          className={indx === 0 ? "panel-heading act" : "panel-heading"}
          role="tab"
          id={"headingTb" + indx}
          onClick={this.handlerActTab.bind(this, indx)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded={indx === 0 ? "true" : "false"}
              href={"#collapse" + indx}
              className={indx === 0 ? "" : "collapsed"}
            >
              {cartBrk["cart_detaile"].break_time_lable} :{" "}
              <span className="hr">
                {timeToConv12(cartBrk["cart_detaile"].break_time_start)}
              </span>
            </a>
          </h4>
        </div>
        <div
          id={"collapse" + indx}
          className={
            indx === 0
              ? "panel-collapse collapse mCustomScrollbar in"
              : "panel-collapse collapse mCustomScrollbar"
          }
          aria-expanded={indx === 0 ? "true" : "false"}
          href={"#collapse" + indx}
        >
          <div className="panel-body">
            <div className="checkout_time">
              <label className="col-sm-6">Select Your Break Time</label>
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="input_area input_lefticons custom_select">
                    <div className="custom-select-inner">
                      <span className="ileft_ico">
                        <img src={ClockImg} />
                      </span>
                      <select
                        name="end_breaktime_inp"
                        className="form-control datetimepicker filled breaktime_endcls"
                        value={cartBrk["cart_detaile"].break_time_start}
                        onChange={this.hdlBrkTimeFun.bind(this, cartBrk)}
                        id={"endbreaktime-" + indx}
                      >
                        {this.state.break_timeHtml}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            {this.cartItemlist(cartBrk["cart_items"])}
          </div>
        </div>
      </div>
    ));
    return cartBrktmhtml;
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1}>
          {/* package div start */}
          <div className="package">
            <div className="row package_cnt">
              <div className="col-sm-4">
                <a
                  href="javascript:void(0)"
                  onClick={this.editPackageDet.bind(this, cartLst)}
                >
                  <span className="edit">
                    <i className="fa fa-pencil-square-o"></i>
                  </span>
                  <span className="title">
                    {stripslashes(cartLst.cart_item_product_name)}
                  </span>
                </a>
              </div>
              <div className="col-sm-4 col-xs-6">
                <div className="qty_bx catqty_bx">
                  <span
                    className="qty_minus"
                    id="ch_5"
                    onClick={this.updateCartQty.bind(
                      this,
                      cartLst,
                      "decrement"
                    )}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    name="pro_qty"
                    value={cartLst.cart_item_qty}
                    readOnly
                  />
                  <span
                    className="qty_plus"
                    id="ch2"
                    onClick={this.updateCartQty.bind(
                      this,
                      cartLst,
                      "increment"
                    )}
                  >
                    +
                  </span>
                </div>
              </div>
              <div className="col-sm-4 col-xs-6">
                <div className="amt">
                  <span>{showPriceValue(cartLst.cart_item_total_price)}</span>
                  <a
                    className="remove"
                    href="javascript:void(0)"
                    onClick={this.removeCartPkg.bind(
                      this,
                      cartLst.cart_item_id
                    )}
                  >
                    X
                  </a>
                </div>
              </div>
            </div>

            {this.showModifiers(cartLst.modifiers)}
          </div>
          {/* package div end */}

          <div className="brline"></div>

          {Object.keys(cartLst.addons_setup).length > 0 ? (
            <div className="addon">
              <h4>ADD-ONS</h4>
              {this.addonsSetupLst(cartLst)}
            </div>
          ) : (
            ""
          )}

          <div className="brline"></div>

          {Object.keys(cartLst.setup).length > 0 ? this.setupDet(cartLst) : ""}

          <div className="brline"></div>

          {cartLst.cart_item_special_notes !== ""
            ? this.specialNotes(cartLst)
            : ""}
        </div>
      ));
      return cartlsthtml;
    }
  }

  showModifiers(mdfLst) {
    if (Object.keys(mdfLst).length > 0) {
      const mdflsthtml = mdfLst.map((mdfLt, indx3) => (
        <div key={indx3} className="package-list">
          <p className="one">{stripslashes(mdfLt.cart_modifier_name)}</p>
          <p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
        </div>
      ));
      return mdflsthtml;
    }
  }

  showMdfValues(mdfValLst) {
    var mdfValueText = "";
    if (Object.keys(mdfValLst).length > 0) {
      var tempVl = Object.keys(mdfValLst).length - 1;
      for (var key in mdfValLst) {
        var cartmdfprice =
          parseFloat(mdfValLst[key].cart_modifier_price) > 0
            ? " ( S$ " + mdfValLst[key].cart_modifier_price + " )"
            : "";
        mdfValueText +=
          parseInt(tempVl) === parseInt(key)
            ? jsUcfirstFun(stripslashes(mdfValLst[key].cart_modifier_name)) +
              cartmdfprice
            : jsUcfirstFun(stripslashes(mdfValLst[key].cart_modifier_name)) +
              cartmdfprice +
              ", ";
      }
    }
    return mdfValueText;
  }

  addonsSetupLst(cartLstDat) {
    var addonsSet = cartLstDat.addons_setup;
    const addonhtml = addonsSet.map((addonSt, indx4) => (
      <div key={indx4} className="row addon_list">
        <div className="col-sm-6 col-xs-8">
          <h5>
            <a
              href="javascript:void(0)"
              onClick={this.editPackageDet.bind(this, cartLstDat)}
            >
              <span className="edit">
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              </span>
              <span className="title">{addonSt.cart_addon_setup_title}</span>
            </a>
          </h5>
          {this.addonsSetupValues(addonSt.addons_setup_values)}
        </div>
        <div className="col-sm-6 col-xs-4">
          <div className="amt">
            <span>{showPriceValue(addonSt.cat_addon_setup_amount)}</span>
            <a
              className="remove"
              href="javascript:void(0)"
              onClick={this.removeCartIndividual.bind(
                this,
                cartLstDat.cart_item_id,
                addonSt.cart_addon_setup_primary_key,
                "addons_setup"
              )}
            >
              X
            </a>
          </div>
        </div>
      </div>
    ));
    return addonhtml;
  }

  addonsSetupValues(addonsVal) {
    if (Object.keys(addonsVal).length > 0) {
      const addonValhtml = addonsVal.map((addonVl, indx5) => (
        <p key={indx5}>
          {addonVl.cart_addon_setup_val_title} (
          {addonVl.cart_addon_setup_val_qty}X)
        </p>
      ));
      return addonValhtml;
    }
  }

  setupDet(cartLstDat) {
    var setupDt = cartLstDat.setup;
    var setupLbl = "";
    var setupPrice = 0;

    for (var key in setupDt) {
      setupLbl = setupDt[key].cart_setup_type;
      setupPrice += parseFloat(setupDt[key].cart_setup_tatalprice);
    }

    return (
      <div className="buffet_setup">
        <div className="row">
          <div className="col-sm-6 col-xs-8">
            <h5>
              <a
                href="javascript:void(0)"
                onClick={this.editPackageDet.bind(this, cartLstDat)}
              >
                <span className="edit">
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </span>
                <span className="title">{setupLbl} Setup</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-4">
            <div className="amt">
              <span>{showPriceValue(setupPrice)}</span>
              <a
                className="remove"
                href="javascript:void(0)"
                onClick={this.removeCartIndividual.bind(
                  this,
                  cartLstDat.cart_item_id,
                  "",
                  "setup"
                )}
              >
                X
              </a>
            </div>
          </div>
        </div>
        <div className="row">{this.setupList(setupDt)}</div>
      </div>
    );
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {stripslashes(setupVl.cart_setup_name)} :{" "}
          {stripslashes(setupVl.cart_setup_description)}
        </p>
      ));
      return setupDathtml;
    }
  }

  specialNotes(cartLstDat) {
    var special_not = cartLstDat.cart_item_special_notes;

    return (
      <div className="buffet_setup" style={{ paddingTop: "0px" }}>
        <div className="row">
          <div className="col-sm-6 col-xs-8">
            <h5>
              <a href="">
                <span className="title">Special Notes</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-4"></div>
        </div>
        <div className="row">
          <p>{special_not}</p>
        </div>
      </div>
    );
  }

  getCartTotalDet() {
    var cartDeta = this.state.cartDetailsMain;
    if (Object.keys(cartDeta).length > 0) {
      var promotionApplied = this.state.promotion_applied;
      var promotionType = this.state.promotion_type;
      var promotionAmount = this.state.promotion_amount;
      var grandTotalAmt =
        promotionApplied === "Yes"
          ? parseFloat(cartDeta.cart_grand_total) - parseFloat(promotionAmount)
          : cartDeta.cart_grand_total;

      var comSettings = this.state.settings;
      var gstPar = 0;
      var gstAmount = 0;
      if (Object.keys(comSettings).length > 0) {
        gstPar =
          comSettings.client_tax_surcharge !== ""
            ? parseFloat(comSettings.client_tax_surcharge)
            : 0;
        var gstAmount = getGstValue(gstPar, grandTotalAmt, "format");
        var gstAmountTxt = getGstValue(gstPar, grandTotalAmt, "");

        grandTotalAmt = parseFloat(grandTotalAmt) + parseFloat(gstAmountTxt);
      }

      return (
        <div className="cart_footer">
          <div className="cart_row">
            <div className="row-replace">
              <div className="col-xs-cls">
                <p className="text-uppercase">SUBTOTAL</p>
              </div>
              <div className="col-xs-cls text-right">
                <span>{showPriceValue(cartDeta.cart_sub_total)}</span>
              </div>
            </div>
          </div>
          {promotionApplied === "Yes" && (
            <div className="cart_row">
              <div className="row-replace">
                <div className="col-xs-cls promo_delico_parent">
                  <p className="text-uppercase">
                    {promotionType === "points"
                      ? "POINTS REDEEMED"
                      : "PROMOTIONS"}
                  </p>
                </div>
                <div className="col-xs-cls text-right">
                  <span>({showPriceValue(promotionAmount)})</span>
                </div>
              </div>
              <a
                href="javascript:void(0)"
                onClick={this.removePromoFun.bind(this)}
                className="cart_remove"
              ></a>
            </div>
          )}
          {parseFloat(gstPar) > 0 && (
            <div className="cart_row">
              <div className="row-replace">
                <div className="col-xs-cls">
                  <p className="text-uppercase">GST ({gstPar} %)</p>
                </div>
                <div className="col-xs-cls text-right">
                  <span>{showPriceValue(gstAmount)}</span>
                </div>
              </div>
            </div>
          )}
          <div className="cart_row grant-total-cls">
            <div className="row-replace">
              <div className="col-xs-cls">
                <p className="text-uppercase">Total</p>
              </div>
              <div className="col-xs-cls text-right">
                <span>{showPriceValue(grandTotalAmt)}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  removePromoFun() {
    this.props.chkoutSateVal("removePromo", "", "");
  }

  updateCartQty(cartItm, type) {
    if (Object.keys(cartItm).length > 0) {
      showLoader("cartlist_main_divcls", "class");
      var cartItemId = cartItm.cart_item_id;
      var cartItemQty = cartItm.cart_item_qty;
      var cartItemMinQty = cartItm.cart_item_min_qty;
      var cartProductId = cartItm.cart_item_product_id;
      var callApi = "Yes";

      if (type === "increment") {
        cartItemQty = parseInt(cartItemQty) + 1;
      } else if (type === "decrement") {
        if (parseInt(cartItemQty) > parseInt(cartItemMinQty)) {
          cartItemQty = parseInt(cartItemQty) - 1;
        } else {
          callApi = "No";
          var msgTxt =
            "Sorry! you can`t reduce to below the minimum quantity ( " +
            cartItemMinQty +
            " )";
          showCustomAlert("error", msgTxt);
          hideLoader("cartlist_main_divcls", "class");
        }
      }

      if (cartItemId !== "" && cartProductId !== "" && callApi === "Yes") {
        var postCrtObject = {};
        postCrtObject = {
          app_id: appId,
          cart_item_id: cartItemId,
          product_id: cartProductId,
          product_qty: cartItemQty,
        };
        if (typeof cookie.load("UserId") === "undefined") {
          postCrtObject["reference_id"] = getReferenceID();
        } else {
          postCrtObject["customer_id"] = cookie.load("UserId");
        }

        axios
          .post(apiUrlV2 + "cateringcart/update", qs.stringify(postCrtObject))
          .then((res) => {
            if (res.data.status === "ok") {
              showCustomAlert("success", "Nice! Products updated to your cart");
              this.handleShowAlertFun(
                "success",
                "Nice! Products updated to your cart"
              );
              this.props.chkoutSateVal(
                "cartLstData",
                res.data.contents.cart_details,
                res.data.contents.cart_items
              );
              this.props.chkoutSateVal("removePromo", "", "");
              this.setState(
                {
                  cartDetailsMain: res.data.contents.cart_details,
                  cartItems: res.data.contents.cart_items,
                },
                function () {
                  this.makeBrkTimeList();
                }.bind(this)
              );
            } else {
              showCustomAlert(
                "error",
                "Sorry! Products can`t update your cart"
              );
              this.handleShowAlertFun(
                "error",
                "Sorry! Products can`t update your cart"
              );
            }
            hideLoader("cartlist_main_divcls", "class");
          })
          .catch(function (error) {
            console.log(error);
            showCustomAlert("error", "something went wrong");
            this.handleShowAlertFun("error", "something went wrong");
            hideLoader("cartlist_main_divcls", "class");
          });
      }
    }
  }

  removeCartIndividual(cartItmId, cartPryId, type) {
    if (
      cartItmId !== "" &&
      (cartPryId !== "" || type === "setup") &&
      type !== ""
    ) {
      showLoader("cartlist_main_divcls", "class");
      var postCrtObject = {};
      postCrtObject = {
        app_id: appId,
        cart_item_id: cartItmId,
        p_id: cartPryId,
        type: type,
      };
      if (typeof cookie.load("UserId") === "undefined") {
        postCrtObject["reference_id"] = getReferenceID();
      } else {
        postCrtObject["customer_id"] = cookie.load("UserId");
      }

      axios
        .post(
          apiUrlV2 + "cateringcart/delete_individual",
          qs.stringify(postCrtObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            showCustomAlert("success", "Nice! Products updated to your cart");
            this.handleShowAlertFun(
              "success",
              "Nice! Products updated to your cart"
            );
            this.props.chkoutSateVal(
              "cartLstData",
              res.data.contents.cart_details,
              res.data.contents.cart_items
            );
            this.props.chkoutSateVal("removePromo", "", "");
            this.setState(
              {
                cartDetailsMain: res.data.contents.cart_details,
                cartItems: res.data.contents.cart_items,
              },
              function () {
                this.makeBrkTimeList();
              }.bind(this)
            );
          } else {
            showCustomAlert("error", "Sorry! Products can`t update your cart");
            this.handleShowAlertFun(
              "error",
              "Sorry! Products can`t update your cart"
            );
          }
          hideLoader("cartlist_main_divcls", "class");
        })
        .catch(function (error) {
          console.log(error);
          showCustomAlert("error", "something went wrong");
          this.handleShowAlertFun("error", "something went wrong");
          hideLoader("cartlist_main_divcls", "class");
        });
    }
  }

  removeCartPkg(cartItmId) {
    if (cartItmId !== "") {
      showLoader("cartlist_main_divcls", "class");

      var cartDetailsMain = this.state.cartDetailsMain;
      var cartCount = cartDetailsMain.cart_total_items;
      if (parseInt(cartCount) === 1) {
        this.cartDestory();
      } else {
        var postCrtObject = {};
        postCrtObject = {
          app_id: appId,
          cart_item_id: cartItmId,
        };
        if (typeof cookie.load("UserId") === "undefined") {
          postCrtObject["reference_id"] = getReferenceID();
        } else {
          postCrtObject["customer_id"] = cookie.load("UserId");
        }

        axios
          .post(apiUrlV2 + "cateringcart/delete", qs.stringify(postCrtObject))
          .then((res) => {
            if (res.data.status === "ok") {
              showCustomAlert("success", "Nice! Products updated to your cart");
              this.handleShowAlertFun(
                "success",
                "Nice! Products updated to your cart"
              );

              var cartDetails = !("cart_details" in res.data.contents)
                ? Array()
                : res.data.contents.cart_details;
              showCartItemCount(cartDetails);

              this.props.chkoutSateVal(
                "cartLstData",
                res.data.contents.cart_details,
                res.data.contents.cart_items
              );
              this.props.chkoutSateVal("removePromo", "", "");

              this.setState(
                {
                  cartDetailsMain: res.data.contents.cart_details,
                  cartItems: res.data.contents.cart_items,
                },
                function () {
                  this.makeBrkTimeList();
                }.bind(this)
              );
            } else {
              showCustomAlert(
                "error",
                "Sorry! Products can`t update your cart"
              );
              this.handleShowAlertFun(
                "error",
                "Sorry! Products can`t update your cart"
              );
            }
            hideLoader("cartlist_main_divcls", "class");
          })
          .catch(function (error) {
            console.log(error);
            showCustomAlert("error", "something went wrong");
            this.handleShowAlertFun("error", "something went wrong");
            hideLoader("cartlist_main_divcls", "class");
          });
      }
    }
  }

  cartDestory() {
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          showCustomAlert("success", "Nice! Your cart is empty");
          var cartDetails = Array();
          showCartItemCount(cartDetails);

          var cartDetailsMain = [];
          var cartItems = [];
          this.setState(
            { cartDetailsMain: cartDetailsMain, cartItems: cartItems },
            function () {
              this.makeBrkTimeList();
            }.bind(this)
          );

          this.handleShowAlertFun("Success", "Sorry!. Your cart is empty.");
          this.props.prpActiveTab(1);
          this.props.history.push("/catering");
        } else {
          showCustomAlert("error", "Sorry! Products can`t update your cart");
          this.handleShowAlertFun(
            "error",
            "Sorry! Products can`t update your cart"
          );
        }
        hideLoader("cartlist_main_divcls", "class");
      })
      .catch(function (error) {
        console.log(error);
        showCustomAlert("error", "something went wrong");
        this.handleShowAlertFun("error", "something went wrong");
        hideLoader("cartlist_main_divcls", "class");
      });
  }

  hdlBrkTimeFun(cartBrk, event) {
    var cartItemArr = cartBrk["cart_items"];
    var seletedTime = event.target.value;
    var cartItmIds = "";
    var cartCnt = Object.keys(cartItemArr).length - 1;
    for (var key in cartItemArr) {
      cartItmIds +=
        parseInt(cartCnt) === key
          ? cartItemArr[key].cart_item_id
          : cartItemArr[key].cart_item_id + "~~";
    }

    if (seletedTime !== "" && cartItmIds !== "") {
      showLoader("cartlist_main_divcls", "class");
      var postCrtObject = {};
      postCrtObject = {
        app_id: appId,
        cart_item_id: cartItmIds,
        break_time: seletedTime,
      };
      if (typeof cookie.load("UserId") === "undefined") {
        postCrtObject["reference_id"] = getReferenceID();
      } else {
        postCrtObject["customer_id"] = cookie.load("UserId");
      }

      axios
        .post(
          apiUrlV2 + "cateringcart/updateBreakTime",
          qs.stringify(postCrtObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            showCustomAlert("success", "Nice! Products updated to your cart");
            this.handleShowAlertFun(
              "success",
              "Nice! Products updated to your cart"
            );
            this.setState(
              {
                cartDetailsMain: res.data.contents.cart_details,
                cartItems: res.data.contents.cart_items,
              },
              function () {
                this.makeBrkTimeList();
              }.bind(this)
            );
          } else {
            showCustomAlert("error", "Sorry! Products can`t update your cart");
            this.handleShowAlertFun(
              "error",
              "Sorry! Products can`t update your cart"
            );
          }
          hideLoader("cartlist_main_divcls", "class");
        })
        .catch(function (error) {
          console.log(error);
          showCustomAlert("error", "something went wrong");
          this.handleShowAlertFun("error", "something went wrong");
          hideLoader("cartlist_main_divcls", "class");
        });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  editPackageDet(cartLst) {
    var cartDetailsSate = this.state.cartDetailsMain;
    var cartDataDetail = [];
    cartDataDetail["cartMain"] = cartDetailsSate;
    cartDataDetail["cartLst"] = cartLst;
    $(".editDivLoadingCls").show();
    this.props.satePackChangefun("editpackage_deta", cartDataDetail);
  }

  handlerTestFun(indx) {}

  downloadCartItem() {
    showLoader("download-quote", "class");
    var customerId = cookie.load("UserId");
    var customerEmail = cookie.load("UserEmail");
    var promotionAmount = this.state.promotion_amount;
    var comSettings = this.state.settings;
    var gstPar = 0;
    if (Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
    }
    var outletIdVl = cookie.load("cateringOutletId");

    var urlParam =
      "&customer_id=" +
      customerId +
      "&request_email=" +
      customerEmail +
      "&client_gst_charge=" +
      gstPar +
      "&promotion=" +
      promotionAmount +
      "&outlet_id=" +
      outletIdVl;

    axios
      .get(
        apiUrlV2 + "cateringcart/downloadQtnItems?app_id=" + appId + urlParam
      )
      .then((res) => {
        if (res.data.status === "ok") {
          showCustomAlert(
            "success",
            "Your Quotation request has been sent to your registered email please check!."
          );
          this.handleShowAlertFun(
            "success",
            "Your Quotation request has been sent to your registered email please check!."
          );
        } else {
          showCustomAlert("error", "Sorry! Your Quotation request was failed.");
          this.handleShowAlertFun(
            "error",
            "Sorry! Your Quotation request was failed."
          );
        }
        hideLoader("download-quote", "class");
      });
  }

  /* ViewProducts */

  render() {
    return (
      <div>
        <div className="chekout_cart_bar">
          <div className="cart-header">
            <div className="text-center checkout-heading">
              <span className="text-uppercase">Order Details</span>
            </div>
          </div>

          {/* order details start */}
          <div className="order_details">
            <div
              className="panel-group cartlist_main_divcls"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              {this.getCartDetList()}
              {this.cartHallData()}
            </div>
          </div>
          {/* order details end */}

          {this.getCartTotalDet()}

          {/*<div className="download-quote">
						<a href="javascript:void(0);" onClick={this.downloadCartItem.bind(this)} className="btn">Download Quotation</a>
						</div>*/}
        </div>
        <div id="dvLoading" className="dvLoadingCls"></div>
      </div>
    );
  }
}
export default withRouter(Cartdetails);
